.Policies {
  button {
    margin-right: 10px;
  }
  label {
    font-size: 1rem;
  }
  .paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .paginationArrow {
    padding: 5px;
    cursor: pointer;
  }
}
