.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: center;
    margin-bottom: 0.5rem;
}

.divider {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &__line {
    width: 100%;
    border-bottom: 1px solid #c0c0c0;
  }
  &__text {
    margin: 0 8px;
    font-size: 14px;
    color: #c0c0c0!important;
  }
}
