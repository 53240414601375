
.PolicyCancellation {
  padding: 40px;
  max-width: 100%;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);


  .reasonDropdown {
    width: 360px;
  }


  .consentCheckbox {
    margin: 16px 0 24px;
  }


  .PolicyNumber {
    margin-top: 16px;
  }

}