.travelersTab {
    min-height: 34rem;

    h3 {
        color: #6c757d;
        font-weight: 400;
        span {
            font-size: 1rem;
        }
    }
    
    .header {
        margin-bottom: 2rem;
    }
    
    .button {
        height: 2.25rem;

        &.ml {
            margin-left: .5rem;
        }
    }

    .label-text {
        font-weight: 400;
        color: #6c757d;
        flex: 0 0 200px;
    }

    .text-value {
        font-weight: 700;
        flex:1;
        font-size: 1.1rem;
    }

    .previewButton {
        margin-right: 4rem;
    }
    
    .medical-info-row {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 2rem;
        button {
            color: #5f5f5f;

            &:hover {
                border-color: #0a76ec;
            }
        }
        
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
    }

    .MuiGrid-item.field-container {
        display: flex;
        align-items: center;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        border: 1px;
        font-size: 1rem;
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
}

.travellersTab-medicalConditions {
    h2 {
      font-size: 1rem;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    > div {
      margin-bottom: 20px;
    }

    p {
      margin: 10px 0;
    }
  }

.travellersTab-noMedicalConditions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}