.price-diff {
  margin: 16px 0;

  &-price-section, &-difference-row {
    display: flex;
    justify-content: space-between;
    max-width: 360px;
    padding-bottom: 12px;
    margin-bottom: 8px;
    align-items: center;
  }

  &-price-section {
    &-initial, &-new {
      display: flex;
      flex-direction: column;

      & > * {
        font-weight: bold;
      }
    }
  }

  &-difference-row {
    & > p {
      font-weight: bold;
    }
  }
}