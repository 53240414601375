.GenericTable {
    thead {
        display: table-header-group;
        .MuiTableCell-root{
            color: #5F5F5F;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.5rem;
        }
    }
    label {
        font-size: 1rem;
    }
    .dataError {
        text-align: center;
        margin: 8rem;
    }
    .searchContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
    }
    .searchInputs {
        display: flex;
        align-items: center;
        flex: 1;
    }
    .search-input {
        margin-right: 10px;
        flex: 1;
    }
    .searchButton {
        flex-shrink: 0;
    }
    .paginationContainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .paginationArrow {
        padding: 5px;
        cursor: pointer;
    }
    .tableRow {
        cursor: pointer;

        .MuiTableCell-root{
            color: #5F5F5F;
            font-size: 0.81rem;
            font-weight: 400;
            padding-top: 1.125rem;
            padding-bottom: 1.125rem;
            line-height: 2rem;
        }

        &:hover {
            .MuiTableCell-root {
                color: #0a76ec;
            }
        }
    }
}
