.welcome-page:not(.welcome-page--widget-only) {
  max-width: 71.875rem;
  margin: 0 auto;
  padding: 0 2rem;

  .welcome-page-container {
    display: grid;
    grid-template-areas: "banner banner"
    "travel-alerts go-to-dasboard"
    "widget quick-links";
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem 1.25rem;
  }

  .banner-area {
    grid-area: banner;
  }

  .travel-alerts-area {
    grid-area: travel-alerts
  }

  .go-to-dashboard-area {
    grid-area: go-to-dasboard
  }

  .widget-area {
    grid-area: widget;
    margin-left: -2px;
    margin-right: -2px;
  }

  .widget-area .script-wrapper {
    transform: translateY(-25%);
  }

  .quick-links-area {
    grid-area: quick-links;
  }
}

.welcome-page--widget-only {
  .travel-alerts,
  .welcome-message,
  .quick-links-container,
  .welcome-questions-container {
    display: none;
  }

  .welcome-get-quote {
    min-height: 100px;
    margin-bottom: 1rem;
    position: absolute;
    width: calc(100% - 48px);
    height: auto;
    z-index: 5;
    top: 99px;
    box-shadow: none;
  }
}

@media screen and (max-width: 960px) {
  .welcome-page:not(.welcome-page--widget-only) {
    .welcome-page-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}