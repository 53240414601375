.dashboard-widget {
  &--closed {
    height: 320px;
  }
  .widget-header {
    padding: 16px 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: "center";
    &__title {
      font-weight: bold;
      font-size: 16px;
    }
    &__button {
      text-decoration: underline;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .widget-footer {
    display: flex;
    padding: 0 16px 16px;
    justify-content: center;
    align-items: flex-end;
    &__text {
      display: flex;
      p {
        font-size: 30px;
        font-weight: 400;
        margin-right: 8px;
      }
      span {
        font-size: 30px;
        font-weight: bold;
      }
    }
    &__trend {
      margin-left: 8px;
      font-size: 30px;
      font-weight: 400;
      color: green;
      &--decrease {
        color: red;
      }
    }
  }
}

.sales-chart.dashboard-widget--closed,
.conversion-rate-chart.dashboard-widget--closed,
.expiring-quotes-widget.dashboard-widget--closed,
.policy-revenue.dashboard-widget--closed {
  .gradient-line-chart {
    display: flex;
    position: relative;
    height: 224px;
    width: 100% !important;
    & > div {
      width: 100% !important;
      display: flex;
      justify-content: center;
      position: relative;
    }
  }
  .widget-footer {
    .widget-footer__trend {
      margin-left: 8px;
      font-size: 16px;
      font-weight: 400;
    }
    &__text {
      font-weight: bold;
      align-items: center;
      p {
        font-size: 24px;
        font-weight: 400;
        margin-right: 8px;
      }
      span {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
}

.expiring-quotes-widget {
  .GenericTable .MuiTableContainer-root {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}

.products-widget {
  &.dashboard-widget--closed {
    .products-legend {
      padding-left: 14px;
      .products-legend__name {
        font-size: 20px;
        white-space: nowrap;
      }
      .products-legend__value {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .products-legend__medium-screen {
    padding-left: 14px;
    .products-legend__color {
      margin-top: -5px;
    }
    .products-legend__name {
      font-size: 16px;
      white-space: nowrap;
    }
    .products-legend__value {
      font-size: 16px;
      font-weight: bold;
    }
  }
  &.dashboard-widget--closed .products-legend.products-legend__many-items {
    padding-left: 14px;
    .products-legend__color {
      margin-top: -5px;
    }
    .products-legend__name {
      font-size: 16px;
      white-space: nowrap;
    }
    .products-legend__value {
      font-size: 16px;
      font-weight: bold;
    }
  }
  &.dashboard-widget--opened .products-legend.products-legend__many-items {
    padding-left: 14px;
    .products-legend__color > span {
      margin-bottom: -4px;
    }
    .products-legend__name {
      font-size: 24px;
      white-space: nowrap;
    }
    .products-legend__value {
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.widgets {
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(440px, 1fr));
}

@media (max-width: 1200px) {
  .widgets {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}
