.PendingQuotes {
  .searchContainer {
    width: 31.25rem;
    justify-self: end;
    @media (max-width: 768px) {
      justify-self: auto;
      width: auto;
    }
  }
  .searchInput {
    width: 100%;
    @media (max-width: 768px) {
      width: auto;
    }
  }
}
