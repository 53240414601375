.travel-alerts {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  line-height: 1.1rem;
  border-radius: .75rem !important;
  .travel-alerts-text {
    font-size: 0.9rem;
  }
}

.travel-alerts-desktop {
  padding: 1rem 2rem;
}

.travel-alerts-mobile {
  padding: 1rem 1.5rem;
}