.policyTab {
    min-height: 34rem;

    .header {
        margin-bottom: 2rem;
        h3 {
            color: #6c757d;
            font-weight: 400;
        }
    }
    
    .label-text {
        font-weight: 400;
        color: #6c757d;
        flex: 0 0 200px;
    }

    .text-value {
        font-weight: 700;
        flex:1;
        font-size: 1.1rem;
    }

    .MuiGrid-item.field-container {
        display: flex;
        align-items: center;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        border: 1px;
        font-size: 1rem;
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
}