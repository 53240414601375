.quick-links-container {
  padding: 0.5rem 1rem;

  .title {
    font-size: 1.625rem;
  }

  .quick-links {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style-type: none;
      margin-bottom: 0.5rem;

      &:hover {
        cursor: pointer;
        font-weight: 900;
      }

      .quick-link-title {
        margin-left: 0.5rem;
        text-decoration: underline;
      }

      .open-right-icon {
        margin-left: auto;
      }
    }
  }
}
