.PolicyDetails {
    .tabs {
        margin: 6rem 0 2rem;
    }

    .tab {
        flex-grow: 0;
        padding: 0.75rem 2rem;
        font-size: 1rem;
        font-weight: 700;
        &:hover {
            outline: 1px solid #0a76ec;
        }
    }

    h5 {
        margin-bottom: 20px;
    }

    label {
        font-size: .85rem;
        font-weight: 500;
    }

    .policyInfo {
        font-size: 1rem;
        padding: 0.25rem;
        padding-left: 2rem;
        margin-bottom: 2rem;
        height: 3rem;
    }

    @media (max-width: 768px) {
        .tabs {
            margin: 5px 0 5px;
        }

        .tab {
            padding: 0.25rem 0.5rem;
            font-size: 0.8rem;
            flex-grow: 1;
        }

        h5 {
            font-size: 1rem;
        }

        label {
            font-size: 0.7rem;
        }

        .policyInfo {
            font-size: 0.7rem;
            padding: 0.25rem;
            margin-bottom: 1rem;
            height: auto;
        }
    }

    .buttonsContainer {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        button {
            color: #5f5f5f;

            &:hover {
                border-color: #0a76ec;
            }
        }

        button:not(:first-child) {
            margin-left: 10px;
        }

        .additionalButtons {
            margin-left: auto;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: stretch;

            button {
                margin-left: 0;
                margin-bottom: 10px;
                width: 100%; /* Make buttons full width */
            }

            .additionalButtons {
                margin-left: 0;
            }

            button:not(:first-child) {
                margin-left: 0;
            }
        }
    }

    .actionButtonsContainer {
        @extend .buttonsContainer;
        button {
            color: white;
        }
    }
}
