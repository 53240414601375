.welcome-message {
  padding: 2rem 1.5rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;

  span {
    line-height: 1.5rem;
  }
}

#link {
  font-size: 1.5625rem;
  color: #909090 !important;
  font-weight: 300 !important;
  line-height: 1.8125rem !important;
  text-wrap: balance !important;

  &:hover {
    color: #909090 !important;
    text-decoration: underline !important;
  }
}

#link:visited {
  color: #909090 !important;
}